import $axios from "@/plugins/axios";
const StatisticsService = {
  async getOrderStatistics(payload) {
    try {
      const { data } = await $axios.get(
        `get-day-status-statistic/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`
      );
      return data;
    } catch (e) {
      return e;
    }
  },
  async getOrderHoursStatistics(payload) {
    try {
      const { data } = await $axios.get(
        `get-day-history-statistic/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`
      );
      return data;
    } catch (e) {
      return e;
    }
  },
  async getReviewsStatistics(payload) {
    try {
      const { data } = await $axios.get(
        `get-polls-history/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`
      );
      return data;
    } catch (e) {
      return e;
    }
  },
  async getUniqueVisits(payload) {
    try {
      const { data } = await $axios.get(
        `get-hotel-unique-visits/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`
      );
      return data;
    } catch (e) {
      return e;
    }
  },
  async getAllVisitorsByDat(payload) {
    try {
      const {data} = await $axios.get(
        `get-statistic-for-period-by-day/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`
      );
      return data;
    } catch (e) {
      return e;
    }
  },
  async getModalBannerStatistics(payload){
    try{
      const {data} = await $axios.get(`get-banner-statistics-for-hotel/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`);
      return data;
    }catch(e){
      return e;
    }
  },
  async getBottomBannerStatistics(payload){
    try{
      const {data} = await $axios.get(`get-bottom-banner-statistics-for-hotel/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`);
      return data;
    }catch(e){
      return e;
    }
  },
  async getIntroStatistics(payload){
    try{
      const {data} = await $axios.get(`intro-banner-statistic/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`);
      return data;
    }catch(e){
      return e;
    }
  },
  async getQuestStatistics(payload){
    try{
      const {data} = await $axios.get(`get-quest-statistic/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`);
      return data;
    }catch(e){
      return e;
    }
  },

  async getSegmentStatistics(payload){
    try{
      const {data} = await $axios.get(`segment-statistic/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`);
      return data;
    }catch(e){
      return e;
    }
  }
};
export default StatisticsService;
